import type { FetchError } from "ofetch";

export type LogInRequest = {
    UserName: string;
    Password: string;
};

export type LogInResponse = {
    ReturnUrl: string;
    AntiForgeryToken: string;
};

export default function useIdentity() {
    const api = useApi();
    // Resist the urge to set isAuthenticated global state here, instead allow useInitializeState to take care of it.

    async function logIn(logInRequest: LogInRequest) {
        try {
            const loginResponse = await api<LogInResponse>("api/identity/sign-in", {
                method: "POST",
                body: logInRequest,
            });

            return loginResponse;
        } catch (err) {
            const fetchError = err as FetchError;
            throw new Error(fetchError.data.Error);
        }
    }

    function logOut() {
        return useFetchApi("api/identity/logout");
    }

    return { logIn, logOut };
}
